import {
  genderOptions,
  intentionPurchase,
  smokeOptions,
} from "../../../utils/staticData";

import { errorMessage } from "../../../utils/error";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";
import AlertIcon from "../../Rebranding/atoms/Icons/AlertIcon.component";
import "./formCalcPrice.styles.scss";
import numberFormat from "../../../utils/numberFormat";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  SelectDS,
  Typography,
} from "get-life-storybook-ts";
import { onEnterBlur } from "../../../utils/onEnterBlur";

export default function FormCalcPriceIT(props) {
  const {
    buttonCalculate,
    capitalLimits,
    death,
    disability,
    error,
    handleSubmitOrganic,
    setCapital,
    setValue,
    showAlerts,
    translate,
    values,
    showResults,
    setError,
    validateBirthdate,
  } = props;

  const textCapital = `${numberFormat(
    capitalLimits.minCapital,
    "capital"
  )} - ${numberFormat(capitalLimits.maxCapital, "capital")}`;

  const isDisability = disability !== undefined && !disability;

  let form = values;
  if (isDisability) {
    form = {
      ...form,
      insuranceType: { value: "basic", label: translate("info.death") },
    };
  }

  const clearInputError = (field) => {
    if (error) {
      const updatedError = [...error];
      const errorItem = updatedError.find((e) => e.field === field);

      if (errorItem) {
        errorItem.message = "";
        errorItem.field = "";
        setError(updatedError);
      }
    }
  };

  return (
    <>
      <div className="newDashboard-tariffifierGrid">
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            label={translate("calculator.label.birthday")}
            type="date"
            onChange={(e) => setValue("date", e.target.value)}
            onBlur={() => {
              clearInputError("date");
              validateBirthdate();
            }}
            value={form["date"]}
            error={errorMessage(error, "date")}
            helper={translate("dashboard.calculator.maxAge")}
            icon="CalendarEventLineIcon"
          />
          <InputDS
            label={translate("calculator.label.zipcode")}
            onChange={(e) => setValue("zipCode", e.target.value)}
            onBlur={() => clearInputError("zipCode")}
            value={form["zipCode"]}
            error={errorMessage(error, "zipCode")}
            placeholder={translate("calculator.placeholder.zipcode")}
            icon="MapPinIcon"
          />
          <SelectDS
            options={genderOptions(translate)}
            value={form["gender"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = genderOptions(translate);
              setValue(
                "gender",
                options.find((e) => e.value === value)
              );
              clearInputError("gender");
            }}
            label={translate("calculator.label.gender")}
            placeholder={translate("calculator.placeholder.gender")}
            error={errorMessage(error, "gender")}
          />
          <InputDS
            label={translate("calculator.label.phone")}
            onChange={(e) => setValue("phone", e.target.value)}
            onBlur={() => clearInputError("phone")}
            value={form["phone"]}
            error={errorMessage(error, "phone")}
            placeholder={translate("calculator.placeholder.phone")}
            icon="DeviceMobile"
          />
          <InputDS
            label={translate("calculator.label.referenceName")}
            onChange={(e) => setValue("referenceName", e.target.value)}
            onBlur={() => clearInputError("referenceName")}
            value={form["referenceName"]}
            error={errorMessage(error, "referenceName")}
            placeholder={translate("calculator.placeholder.referenceName")}
            icon="UserSearchIcon"
          />
          <InputDS
            label={translate("calculator.label.email")}
            onChange={(e) => setValue("email", e.target.value)}
            onBlur={() => clearInputError("email")}
            value={form["email"]}
            error={errorMessage(error, "email")}
            placeholder={translate("calculator.placeholder.email")}
            icon="MailIcon"
          />
          <SelectDS
            options={smokeOptions(translate)}
            value={form["smoker"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = smokeOptions(translate);
              setValue(
                "smoker",
                options.find((e) => e.value === value)
              );
              clearInputError("smoker");
            }}
            label={translate("calculator.label.smoker")}
            placeholder={translate("calculator.placeholder.smoker")}
            error={errorMessage(error, "smoker")}
            icon="SmokingIcon"
          />
          <SelectDS
            options={intentionPurchase(translate)}
            value={form["intention"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = intentionPurchase(translate);
              setValue(
                "intention",
                options.find((e) => e.value === value)
              );
              clearInputError("intention");
            }}
            label={translate("calculator.label.intention")}
            placeholder={translate("calculator.placeholder.intention")}
            error={errorMessage(error, "intention")}
            icon="ShieldChevronIcon"
          />
        </div>
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            type="number"
            label={translate("calculator.label.capital")}
            placeholder="200.000"
            name="capital"
            value={form["capital"]}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              if (isNaN(value)) value = null;
              setCapital(value, setValue);
            }}
            onBlur={() => {
              let value = form["capital"];
              if (value > capitalLimits.maxCapital) {
                value = capitalLimits.maxCapital;
              }
              if (value < capitalLimits.minCapital) {
                value = capitalLimits.minCapital;
              }
              if (!value) value = capitalLimits.minCapital;
              setCapital(value, setValue);
            }}
            error={death && errorMessage(error, "capital")}
            disabled={form["capital"] === undefined}
            icon="CurrencyEuroIcon"
            onKeyDown={(e) => {
              if (e.key === "," || e.key === ".") e.preventDefault();
            }}
            helper={
              capitalLimits.minCapital && capitalLimits.maxCapital
                ? `${translate(
                    "edit.projects.minimum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(capitalLimits.minCapital)}, ${translate(
                    "edit.projects.maximum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(capitalLimits.maxCapital)}`
                : null
            }
            step={5000}
            min={capitalLimits.minCapital}
            max={capitalLimits.maxCapital}
            onKeyDownCapture={onEnterBlur}
          />
          {showAlerts.maxCapital && (
            <InfoMessage
              className="infoMessage"
              classNameText="infoMessage-text"
              icon={<AlertIcon color="#423EF4" width={30} height={30} />}
              message={
                <>
                  {translate("info.dashboard.capitalLimits")}{" "}
                  <Typography
                    variant="BodyMedium"
                    weight="700"
                    className="infoMessage-emphasis"
                    text={textCapital}
                  />
                </>
              }
              type="info"
            />
          )}
          <div>
            <p className="BodyL text-[#424242] mb-[16px]">
              {translate("calculator.label.insuranceType")}
            </p>
            <div className="flex flex-row gap-[8px] items-center">
              <GlobalIcon
                iconName="UmbrellaIcon"
                size="XS"
                color="var(--primary-color)"
              />
              <span className="BodyM font-medium text-[#424242] mr-auto">
                {translate("calculator.label.terminalIllness")}
              </span>
              <span className="BodyM font-bold text-[var(--primary-color)]">
                {translate("dashboard.calcualtor.included")}
              </span>
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                size="XS"
                color="#018565"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[24px] flex">
        <ButtonDS
          label={translate(`dashboard.buttons.${buttonCalculate}`)}
          rightIcon={showResults ? undefined : "ArrowRightIcon"}
          className="newDashboard-tariffifierCTA"
          onClick={() => handleSubmitOrganic(form, setValue)}
          buttonType={showResults ? "tertiary" : "primary"}
        />
      </div>
    </>
  );
}
