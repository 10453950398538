import { LanguageT } from "../contexts/ContextGetlife";

type PhonePrefixI = {
  [key in LanguageT]: { prefix: string; replace: string };
};

export type GlobalKeyStringI = {
  [k in LanguageT]: string;
};

type BeneficiariesI = {
  [key in LanguageT]: {
    id:
      | "legalHeirs"
      | "prelationOrder"
      | "other"
      | "testamentHeirs"
      | "myChildren"
      | "mySpouse";
    label: string;
    name:
      | "legalHeirs"
      | "prelationOrder"
      | "other"
      | "testamentHeirs"
      | "myChildren"
      | "mySpouse";
    value: string;
  }[];
};

export const PhonePrefix: PhonePrefixI = {
  es: { prefix: "0034", replace: "" },
  en: { prefix: "0034", replace: "" },
  fr: { prefix: "0033", replace: "0" },
  pt: { prefix: "0034", replace: "" },
  it: { prefix: "0039", replace: "" },
  wl_es: { prefix: "0034", replace: "" },
  sql_es: { prefix: "0034", replace: "" },
};

export const DaysAvailable = {
  es: 90,
  en: 90,
  fr: 30,
  pt: 90,
  it: 30,
  wl_es: 90,
  sql_es: 90,
};

export const DayAvailable = {
  es: 0,
  en: 0,
  fr: 0,
  pt: 0,
  it: 1,
  wl_es: 0,
  sql_es: 0,
};

export const GlobalCitizenship: GlobalKeyStringI = {
  es: "ES",
  fr: "FR",
  en: "",
  pt: "",
  it: "IT",
  wl_es: "ES",
  sql_es: "ES",
};

export const GlobalGoogleCountry: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
};

export const GlobalStripeLocale: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
};

export const GlobalProvinceApi: GlobalKeyStringI = {
  es: "province_spain",
  fr: "province_france",
  en: "province_spain",
  pt: "province_spain",
  it: "city_italia",
  wl_es: "province_spain",
  sql_es: "province_spain",
};

export const GlobalCountryApi: GlobalKeyStringI = {
  es: "country",
  fr: "country_fr",
  en: "country",
  pt: "country",
  it: "country_it",
  wl_es: "country",
  sql_es: "country",
};

export const GlobalBeneficiaries: BeneficiariesI = {
  es: [
    {
      label: "questions.beneficiaries.legalHeirs",
      value: "Herederos Legales",
      name: "legalHeirs",
      id: "legalHeirs",
    },
    {
      label: "questions.beneficiaries.orderOfPrecedence",
      value: "Orden de Prelación",
      name: "prelationOrder",
      id: "prelationOrder",
    },
    {
      label: "questions.beneficiaries.otherBeneficiaries",
      value: "other",
      name: "other",
      id: "other",
    },
  ],
  fr: [
    {
      label: "questions.beneficiaries.legalHeirs",
      value: "Herederos Legales",
      name: "legalHeirs",
      id: "legalHeirs",
    },
    {
      label: "questions.beneficiaries.otherBeneficiaries",
      value: "other",
      name: "other",
      id: "other",
    },
  ],
  en: [],
  pt: [],
  it: [
    {
      value: "Legal heirs",
      label: "questions.beneficiaries.legal",
      name: "legalHeirs",
      id: "legalHeirs",
    },
    {
      value: "My children",
      label: "questions.beneficiaries.children",
      name: "myChildren",
      id: "myChildren",
    },
    {
      value: "Testament heirs",
      label: "questions.beneficiaries.testament",
      name: "testamentHeirs",
      id: "testamentHeirs",
    },
    {
      value: "My spouse",
      label: "questions.beneficiaries.spouse",
      name: "mySpouse",
      id: "mySpouse",
    },
  ],
  wl_es: [
    {
      label: "questions.beneficiaries.legalHeirs",
      value: "Herederos Legales",
      name: "legalHeirs",
      id: "legalHeirs",
    },
    {
      label: "questions.beneficiaries.otherBeneficiaries",
      value: "other",
      name: "other",
      id: "other",
    },
  ],
  sql_es: [
    {
      label: "questions.beneficiaries.legalHeirs",
      value: "Herederos Legales",
      name: "legalHeirs",
      id: "legalHeirs",
    },
    {
      label: "questions.beneficiaries.orderOfPrecedence",
      value: "Orden de Prelación",
      name: "prelationOrder",
      id: "prelationOrder",
    },
    {
      label: "questions.beneficiaries.otherBeneficiaries",
      value: "other",
      name: "other",
      id: "other",
    },
  ],
};

export const GlobalLocaleCalendar: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
};