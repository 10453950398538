import "./BannerEnvironment.scss";
import React from "react";

interface BannerEnvironmentI {
  environment: string | undefined;
  locale: string | undefined;
}

const BannerEnvironment: React.FC<BannerEnvironmentI> = ({
  environment,
  locale,
}) => {

  return (
    <>
      <div className={`BannerEnvironment ${environment}`}>
        <p>
          YOU ARE IN {environment} .{locale!.toUpperCase()} ENVIRONMENT
        </p>
      </div>
    </>
  );
};

export default BannerEnvironment;
