import { Navigate, Outlet } from "react-router-dom";
import Header from "../views/components/header/Header";
import BannerEnvironment from "../views/components/BannerEnvironment/BannerEnvironment";

const PrivateRoute = ({ token }) => {
  return token ? (
    <Header>
       {process.env.REACT_APP_ENVIROMENT !== "PRD" && (
        <BannerEnvironment environment={process.env.REACT_APP_ENVIROMENT} locale={process.env.REACT_APP_DOMAIN_LOCALE} />
      )}
      <Outlet />
    </Header>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
